import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';

const LoginComponent = () => {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState('');
  const [sheetsUrl, setSheetsUrl] = useState('');
  const [sheetsUrlBasic, setSheetsUrlBasic] = useState('')
  const [gidBasic, setGidBasic] = useState('')

  const handleLoginClick = () => {
    window.open('https://postsheetapp.com/accounts/google/login/', '_blank');
  };

  const verifyToken = async () => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get('email') || userEmail;
    const sheetsUrlFromUrl = params.get('sheets_url') || sheetsUrlBasic;
    const gid = params.get('gid') || gidBasic;
  

  
    const url = `https://postsheetapp.com/api/check-auth-by-email`;
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Falha na autenticação: ${errorData.error || 'Erro desconhecido'}`);
      }
  
      const data = await response.json();

  
      if (data.isAuthenticated) {

        const queryParams = new URLSearchParams({
          email: encodeURIComponent(email),
          sheets_url: encodeURIComponent(sheetsUrlFromUrl),
          gid: encodeURIComponent(gid),
        }).toString();

        navigate(`/action-selector-extension?${queryParams}`);
      }
      if (!data.data.can_still_run) {
        navigate('/exceeded');
      }
      if (data.data.expired) {
        navigate('/expired');
      }
    } catch (error) {
      console.error('Erro ao verificar a autenticação:', error.message);
    }
  };

  useEffect(() => {


    // Definir userEmail apenas se ele ainda não estiver setado
    const params = new URLSearchParams(window.location.search);
    const email = params.get('email') || '';
    const sheetsUrlFromUrl = params.get('sheets_url');
    const gid = params.get('gid');

    if (email && !userEmail) {
      setUserEmail(email);
    }
    if (sheetsUrlFromUrl) {
      setSheetsUrl(`${sheetsUrlFromUrl}?gid=${gid}#gid=${gid}`);
      setSheetsUrlBasic(sheetsUrlFromUrl)
      setGidBasic(gid)
 
    }
    // Inicia a verificação a cada 1500ms
    const interval = setInterval(() => {
      verifyToken();
    }, 1500);

    return () => {

      clearInterval(interval);
    };
  }, [userEmail, navigate]);

  return (
    <>
      <div className="container" style={{ padding: '20px', width: '100%' }}>
        <div className="grid grid-cols-1 gap-4">
          <div className="confined">
            <h1 className="font-bold text-lg leading-tight" style={{ maxWidth: '15rem' }}>
              To use the extension you should be logged in to PostSheet!
            </h1>
            {userEmail && (
              <p className="text-sm text-gray-500">Logged in as: {userEmail}</p>
            )}
            <button
              onClick={handleLoginClick}
              className="button-primary"
              style={{ display: 'block', marginTop: '20px', fontSize: '14px' }}
            >
              Proceed to Login
            </button>
          </div>

          <div className="mt-4">
            <video
              id="video-new-job-action-configure-send-email"
              autoPlay
              loop
              playsInline
              muted
              style={{ width: '100%', borderRadius: '8px' }}
            >
              <source src="../../static/content/guides/sign-in-video-google-options.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginComponent;
