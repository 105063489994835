import React, { useEffect, useState } from 'react';
import { Box, Paper, List, ListItem, ListItemText, CircularProgress, Typography, Snackbar, Alert } from "@mui/material";
import { useDatabase } from '../../../contexts/DatabaseContext';

const DatabaseList = () => {
  const { fetchUserCredentials } = useDatabase();
  const [credentials, setCredentials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    async function loadCredentials() {
      try {
        const data = await fetchUserCredentials();
        setCredentials(data);
      } catch (err) {
        setError(err.message);
        setOpenAlert(true);
      } finally {
        setLoading(false);
      }
    }
    loadCredentials();
  }, [fetchUserCredentials]);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  if (loading) {
    return (
      <Paper sx={{ width: "100%", overflow: "hidden", padding: '1rem' }}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      </Paper>
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" sx={{ width: "100%" }}>
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      <Paper sx={{ width: "100%", padding: '1.5rem', marginTop: '1rem' }}>
        <Typography variant="h2" sx={{ marginBottom: '1rem' }}>My Databases</Typography>
        {credentials.length > 0 ? (
          <List>
            {credentials.map((cred) => (
              <ListItem key={cred.id}>
                <ListItemText 
                  primary={cred.database_name} 
                  secondary={cred.database_type}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography align="center">No databases found</Typography>
        )}
      </Paper>
    </Box>
  );
};

export default DatabaseList;
