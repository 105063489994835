import React, { useState, useEffect, useCallback } from 'react';
import { TextField, MenuItem, Box, Paper, CircularProgress } from '@mui/material';
import { useNewCampaign } from '../../../../../contexts/NewCampaignContext';
import { H2, SubHeader } from '../../../../../styled-components/Typography';
import { useNavigate } from 'react-router-dom';

function DatabasePanel() {
  const {
    updateActionConfig,
    updateDataSourceConfig,
    selectedBaseId,       // Represents the selected database ID.
    selectedTableId,
    setSelectedBaseId,
    setSelectedTableId,
    isDataFetched, setIsDataFetched
  } = useNewCampaign();
  const navigate = useNavigate();
  
  // State to hold fetched databases and tables, and loading indicator
  const [databases, setDatabases] = useState([]);
  const [tables, setTables] = useState([]);
  const [databaseData, setDatabaseData] = useState(null);
  const [loadingDatabases, setLoadingDatabases] = useState(true);

  // Helper: get CSRF token from cookies
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }
  const csrftoken = getCookie("csrftoken");

  // Record errors to your backend error logging endpoint
  const recordError = async (errorMessage, apiUrl, requestBody) => {
    try {
      const errorRecordUrl = `https://postsheetapp.com/api/error-record`;
      await fetch(errorRecordUrl, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({
          error_message: errorMessage,
          api_url: apiUrl,
          request: requestBody,
        }),
      });
    } catch (err) {
      console.error('Failed to record the error:', err);
      navigate('/app/error', { state: { errorMessage: err.message } });
    }
  };

  // Fetch the user databases automatically on mount
  const fetchDatabases = useCallback(async () => {
    console.log("Fetching databases...");
    try {
      const response = await fetch('https://postsheetapp.com/api/credentials/list/', {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch databases: ${response.statusText}`);
      }
      const data = await response.json();
      console.log("Fetched databases:", data);
      setDatabases(data); // Expecting an array of database objects with id and database_name
    } catch (error) {
      console.error('Error fetching databases:', error);
      recordError(error.message, 'https://postsheetapp.com/api/credentials/list/', {});
      navigate('/app/error', { state: { errorMessage: error.message } });
    } finally {
      setLoadingDatabases(false);
    }
  }, [csrftoken, recordError, navigate]);

  useEffect(() => {
    if(!databases) {
        fetchDatabases();
    }
    
  }, [fetchDatabases]);

  // Fetch the table data for a given database and table
  const fetchDatabaseData = useCallback(async (databaseId, tableId) => {
    console.log("Fetching data for database:", databaseId, "table:", tableId);
    if (!databaseId || !tableId) {
      setDatabaseData(null);
      return;
    }
    setIsDataFetched(false);
    try {
      const response = await fetch(`https://postsheetapp.com/api/database/get-data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({
          database_id: databaseId,
          table_name: tableId,
        })
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch database data: ${response.statusText}`);
      }
      const data = await response.json();
      console.log("Fetched table data:", data);
      setDatabaseData(data);
      updateDataSourceConfig({ database_id: databaseId, table_id: tableId });
      updateActionConfig({
        databaseData: data.database_data,
        columns: data.database_data.columns,
        rows: data.database_data.rows,
        row_count: data.database_data.row_count
      });
    } catch (error) {
      console.error('Error fetching database data:', error);
      setDatabaseData(null);
      recordError(error.message, `https://postsheetapp.com/api/database/get-data`, { database_id: databaseId, table_name: tableId });
      navigate('/app/error', { state: { errorMessage: error.message } });
    } finally {
      setIsDataFetched(true);
    }
  }, [csrftoken, updateActionConfig, updateDataSourceConfig, setIsDataFetched]);

  // Fetch tables available for a given database
  const fetchTablesForDatabase = useCallback(async (databaseId) => {
    console.log("Fetching tables for database:", databaseId);
    if (!databaseId) {
      setTables([]);
      return;
    }
    try {
      const response = await fetch(`https://postsheetapp.com/api/database/get-tables`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Database-Id': databaseId
        }
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch tables: ${response.statusText}`);
      }
      const data = await response.json();
      console.log("Fetched tables:", data);
      setTables(data.tables);
    } catch (error) {
      console.error('Error fetching tables:', error);
      setTables([]);
      recordError(error.message, `https://postsheetapp.com/api/database/get-tables`, { database_id: databaseId });
      navigate('/app/error', { state: { errorMessage: error.message } });
    }
  }, [recordError, navigate]);

  // Handle changes in the Database select
  const handleDatabaseChange = (event) => {
    const newDatabaseId = event.target.value;
    setSelectedBaseId(newDatabaseId);
    fetchTablesForDatabase(newDatabaseId);
  };

  // Handle changes in the Table select
  const handleTableChange = (event) => {
    const newTableId = event.target.value;
    setSelectedTableId(newTableId);
  };

  useEffect(() => {
    if (selectedBaseId && selectedTableId) {
      fetchDatabaseData(selectedBaseId, selectedTableId);
    }
  }, [selectedBaseId, selectedTableId, fetchDatabaseData]);

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  // Render a loading indicator while databases are being fetched
  if (loadingDatabases) {
    return (
      <Paper sx={{ width: "100%", overflow: "hidden", padding: '1rem' }}>
        <Box display="flex" justifyContent="center" alignItems="center" height="150px">
          <CircularProgress />
        </Box>
      </Paper>
    );
  }

  return (
    <Paper elevation={3} sx={{ p: 4, m: 2 }}>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <H2>New Campaign</H2>
        <SubHeader>Which Database and Table to use?</SubHeader>

        <TextField
          select
          label="Database"
          value={selectedBaseId || ""}
          onChange={handleDatabaseChange}
          fullWidth
          sx={{ mt: 3 }}
        >
          {databases.map((db) => (
            <MenuItem key={db.id} value={db.id}>
              {db.database_name}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          label="Table"
          value={selectedTableId || ""}
          onChange={handleTableChange}
          fullWidth
          disabled={tables.length === 0}
          sx={{ mt: 3 }}
        >
          {tables.map((table) => (
            <MenuItem key={table.id} value={table.id}>
              {table.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </Paper>
  );
}

export default DatabasePanel;
