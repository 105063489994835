// CredentialsForm.js
import React from 'react';
import { Paper, Typography, TextField, Box, Button, CircularProgress } from "@mui/material";

const CredentialsForm = ({
  credentials,
  setCredentials,
  onSubmit,
  loading,
  submitLabel = "Submit",
  onCancel,
  additionalButtons,
}) => {
  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  return (
    <Paper elevation={3} sx={{ p: 4, m: 2 }}>
      <Typography variant="h6">Database Credentials</Typography>
      <TextField
        label="Host"
        name="host"
        value={credentials.host}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Port"
        name="port"
        value={credentials.port}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Database Name"
        name="database"
        value={credentials.database}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Username"
        name="username"
        value={credentials.username}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Password"
        name="password"
        value={credentials.password}
        onChange={handleChange}
        type="password"
        fullWidth
        margin="normal"
      />
      <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
        <Button variant="contained" onClick={onSubmit} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : submitLabel}
        </Button>
        {onCancel && <Button variant="outlined" onClick={onCancel}>Cancel</Button>}
        {additionalButtons}
      </Box>
    </Paper>
  );
};

export default CredentialsForm;
