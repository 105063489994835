// DatabaseProvider.js
import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const DatabaseContext = createContext();

export const useDatabase = () => useContext(DatabaseContext);

export const DatabaseProvider = ({ children }) => {
  const navigate = useNavigate();

  // State for database connection and query management
  const [credentials, setCredentials] = useState({
    host: '',
    port: '',
    database: '',
    username: '',
    password: '',
  });
  const [databaseType, setDatabaseType] = useState('');
  const [connected, setConnected] = useState(false);
  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState('');
  const [tableData, setTableData] = useState({ columns: [], rows: [] });
  const [queryResult, setQueryResult] = useState({ columns: [], rows: [] });
  const [loading, setLoading] = useState(false);
  const [loadingQuery, setLoadingQuery] = useState(false);
  const [error, setError] = useState(null);

  // Utility: Get cookie value
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }
  const csrftoken = getCookie("csrftoken");

  // Utility: Record errors to the backend error logging endpoint
  const recordError = async (errorMessage, apiUrl, requestBody) => {
    try {
      const errorRecordUrl = `https://postsheetapp.com/api/error-record`;
      await fetch(errorRecordUrl, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({
          error_message: errorMessage,
          api_url: apiUrl,
          request: requestBody,
        }),
      });
    } catch (err) {
      console.error('Failed to record the error:', err);
      navigate('/app/error', { state: { errorMessage: err.message } });
    }
  };
  const fetchUserCredentials = async () => {

    setLoading(true);
    try {
      console.log("Entrou em try do fetch user credentials")
      const response = await fetch('https://postsheetapp.com/api/credentials/list/', {
        method: 'GET',
        credentials: 'include',
        headers: {
          'X-CSRFToken': csrftoken,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch user credentials');
      }
      const data = await response.json();
      console.log("Data is", data)
      return data; // Expected to be an array of objects with id, database_name, and database_type
    } catch (err) {
      setError(err);
      recordError(err.message, 'https://postsheetapp.com/api/credentials/list/', {});
      navigate('/app/error', { state: { errorMessage: err.message } });
      return [];
    } finally {
      setLoading(false);
    }
  };
  // Connect to the database using provided credentials
  const connectToDatabase = async (dbType, creds) => {
    setLoading(true);
    let payload = {}
    try {
      // Prepare payload with correct key mapping
      payload = {
        host: creds.host,
        port: creds.port,
        user_name: creds.username, // mapping: frontend "username" -> backend "user_name"
        database_name: creds.database, // mapping: frontend "database" -> backend "database_name"
        password: creds.password,
        database_type: dbType,
      };
  
      // Call the credentials/create endpoint to create a record
      const response = await fetch(`https://postsheetapp.com/api/credentials/create/`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify(payload),
      });
      
      if (!response.ok) {
        throw new Error('Failed to create credential');
      }
      
      // The backend returns the created credential
      const data = await response.json();
      
      // Now you might also want to set the connection state or fetch table list if needed
      setConnected(true);
      // Optionally: fetch tables or update other states based on data
    } catch (err) {
      setError(err);
      recordError(err.message, 'https://postsheetapp.com/api/credentials/create/', payload);
      navigate('/app/error', { state: { errorMessage: err.message } });
      setConnected(false);
    } finally {
      setLoading(false);
    }
  };
  // Fetch data for a specific table
  const fetchTableData = async (tableName) => {
    setLoading(true);
    try {
      const response = await fetch(`https://postsheetapp.com/api/database/table-data`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({ table_name: tableName }),
      });
      if (!response.ok) {
        throw new Error('Failed to fetch table data');
      }
      const data = await response.json();
      // Expecting the backend to return columns and rows
      setTableData({ columns: data.columns, rows: data.rows });
    } catch (err) {
      setError(err);
      recordError(err.message, 'https://postsheetapp.com/api/database/table-data', { tableName });
      navigate('/app/error', { state: { errorMessage: err.message } });
    } finally {
      setLoading(false);
    }
  };

  // Run a SQL query and return the results
  const runQuery = async (query) => {
    setLoadingQuery(true);
    try {
      const response = await fetch(`https://postsheetapp.com/api/database/run-query`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({ query }),
      });
      if (!response.ok) {
        throw new Error('Failed to run query');
      }
      const data = await response.json();
      // Expecting the backend to return columns and rows
      setQueryResult({ columns: data.columns, rows: data.rows });
      return { columns: data.columns, rows: data.rows };
    } catch (err) {
      setError(err);
      recordError(err.message, 'https://postsheetapp.com/api/database/run-query', { query });
      navigate('/app/error', { state: { errorMessage: err.message } });
      return { columns: [], rows: [] };
    } finally {
      setLoadingQuery(false);
    }
  };

  // Save query result as a new table
  const saveQueryResult = async (result) => {
    try {
      const response = await fetch(`https://postsheetapp.com/api/database/save-query-result`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({ result }),
      });
      if (!response.ok) {
        throw new Error('Failed to save query result');
      }
      alert('Query result saved as a table!');
    } catch (err) {
      setError(err);
      recordError(err.message, 'https://postsheetapp.com/api/database/save-query-result', { result });
      navigate('/app/error', { state: { errorMessage: err.message } });
    }
  };

  // Create a database view using a SQL query
  const createView = async (query) => {
    try {
      const response = await fetch(`https://postsheetapp.com/api/database/create-view`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({ query }),
      });
      if (!response.ok) {
        throw new Error('Failed to create view');
      }
      alert('View created successfully!');
    } catch (err) {
      setError(err);
      recordError(err.message, 'https://postsheetapp.com/api/database/create-view', { query });
      navigate('/app/error', { state: { errorMessage: err.message } });
    }
  };

  // -----------------------------
  // CRUD operations for Credentials
  // -----------------------------

  // List all credentials for the current user
  const listCredentials = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://postsheetapp.com/api/credentials/', {
        method: 'GET',
        credentials: 'include',
        headers: {
          'X-CSRFToken': csrftoken,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch credentials');
      }
      const data = await response.json();
      return data; // expecting an array of credential objects
    } catch (err) {
      setError(err);
      recordError(err.message, 'https://postsheetapp.com/api/credentials/', {});
      navigate('/app/error', { state: { errorMessage: err.message } });
      return [];
    } finally {
      setLoading(false);
    }
  };

  // Get details of a specific credential by ID
  const getCredential = async (id) => {
    setLoading(true);
    try {
      const response = await fetch(`https://postsheetapp.com/api/credentials/${id}/`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'X-CSRFToken': csrftoken,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch credential details');
      }
      const data = await response.json();
      return data;
    } catch (err) {
      setError(err);
      recordError(err.message, `https://postsheetapp.com/api/credentials/${id}/`, {});
      navigate('/app/error', { state: { errorMessage: err.message } });
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Create a new credential
  const createCredential = async (credentialData) => {
    setLoading(true);
    try {
      const response = await fetch('https://postsheetapp.com/api/credentials/create/', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify(credentialData),
      });
      if (!response.ok) {
        throw new Error('Failed to create credential');
      }
      const data = await response.json();
      return data;
    } catch (err) {
      setError(err);
      recordError(err.message, 'https://postsheetapp.com/api/credentials/create/', credentialData);
      navigate('/app/error', { state: { errorMessage: err.message } });
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Update an existing credential
  const updateCredential = async (id, credentialData) => {
    setLoading(true);
    try {
      const response = await fetch(`https://postsheetapp.com/api/credentials/${id}/update/`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify(credentialData),
      });
      if (!response.ok) {
        throw new Error('Failed to update credential');
      }
      const data = await response.json();
      return data;
    } catch (err) {
      setError(err);
      recordError(err.message, `https://postsheetapp.com/api/credentials/${id}/update/`, credentialData);
      navigate('/app/error', { state: { errorMessage: err.message } });
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Delete a credential
  const deleteCredential = async (id) => {
    setLoading(true);
    try {
      const response = await fetch(`https://postsheetapp.com/api/credentials/${id}/delete/`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'X-CSRFToken': csrftoken,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to delete credential');
      }
      const data = await response.json();
      return data;
    } catch (err) {
      setError(err);
      recordError(err.message, `https://postsheetapp.com/api/credentials/${id}/delete/`, {});
      navigate('/app/error', { state: { errorMessage: err.message } });
      return null;
    } finally {
      setLoading(false);
    }
  };

  return (
    <DatabaseContext.Provider
      value={{
        credentials,
        setCredentials,
        databaseType,
        setDatabaseType,
        connected,
        connectToDatabase,
        tables,
        fetchTableData,
        tableData,
        selectedTable,
        setSelectedTable,
        runQuery,
        queryResult,
        setQueryResult,
        saveQueryResult,
        createView,
        loading,
        loadingQuery,
        error,
        // CRUD functions for credentials
        listCredentials,
        getCredential,
        createCredential,
        updateCredential,
        deleteCredential,
        fetchUserCredentials
      }}
    >
      {children}
    </DatabaseContext.Provider>
  );
};

export default DatabaseProvider;
