import React, { useEffect, useState } from 'react';
import { Paper, Box, CircularProgress } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { useSheetsExtension } from '../../pages/sheets_extension/ActionConfig/SheetsExtensionContext';

const ProtectedRoute = ({ children }) => {
  const { userEmailGlobal, checkAuthenticationByEmail } = useSheetsExtension();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const verifyAuthentication = async () => {

      try {
        if (userEmailGlobal) {
          await checkAuthenticationByEmail(userEmailGlobal); // Call authentication function
          setIsAuthenticated(true);
        } else {

          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Authentication failed:', error);
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false); // Stop loading spinner
      }
    };

    verifyAuthentication();
  }, [userEmailGlobal, checkAuthenticationByEmail]);

  if (isLoading) {
    return   <Paper sx={{ width: "100%", overflow: "hidden", padding: '1rem' }}>
    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
      <CircularProgress />
    </Box>
  </Paper> // Show a loading spinner or placeholder while checking
  }

  if (!isAuthenticated) {
    return <Navigate to="/login-extension" />; // Redirect to login if not authenticated
  }

  return children; // Render the protected component if authenticated
};

export default ProtectedRoute;
