import React, { useEffect, useState } from "react";
import { Paper, Typography, Box, TextField, Checkbox, FormControlLabel } from "@mui/material";
import { H2, SubHeader } from "../../../../styled-components/Typography";
import ColumnsChips from "./Editor/ColumnsChips";
import EmailForm from "./Editor/Form";
import TextEditor from "./Editor/Editor";
import { useNewCampaign } from "../../../../contexts/NewCampaignContext";
import TwilioForm from "./Editor/TwilioForm";

function TwilioActionConfig({ columns }) {
  const { setTwilioData, twilioData, activeInputRef, actionType } = useNewCampaign();
  const [includeOptOut, setIncludeOptOut] = useState(true);

  const defaultUnsubMessage = "If you'd like me to stop sending you emails, please";
  const defaultUnsubLinkText = "click here";

  useEffect(() => {

    if (includeOptOut) {
      if (!twilioData.unsubMessage) {
        setTwilioData('unsubMessage', defaultUnsubMessage);
      }
      if (!twilioData.unsubLink) {
        setTwilioData('unsubLink', defaultUnsubLinkText);
      }
    }
  }, [includeOptOut, setTwilioData, twilioData.unsubMessage, twilioData.unsubLink]);

  const handleOptOutChange = (event) => {
    setIncludeOptOut(event.target.checked);
    if (!event.target.checked) {
      setTwilioData('unsubMessage', '');
      setTwilioData('unsubLink', '');
    } else {
      setTwilioData('unsubMessage', twilioData.unsubMessage || defaultUnsubMessage);
      setTwilioData('unsubLink', twilioData.unsubLink || defaultUnsubLinkText);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTwilioData(name, value);
  };

  const handleFocus = (event) => {
    activeInputRef.current = event.target;
  };

  const previewMessage = `${twilioData.unsubMessage || defaultUnsubMessage} <a href="#">${twilioData.unsubLink || defaultUnsubLinkText}</a>`;

  return (
    <Paper elevation={3} sx={{ p: 4, m: 2 }}>
      {actionType == "twilio.send-whatsapp" && (
          <Box sx={{ mt: 2, p: 2, mb:2, backgroundColor: '#fde9e2', borderRadius: '4px' }}>
                  <Typography sx={{ color: '#8a3c09' }}>
                   <b> To run a WhatsApp campaign you should have a Twilio WhatsApp number, set up a WhatsApp number on your twilio dashboard to run campaigns.</b>
                  </Typography>
                </Box>
      )}
      <H2>New Campaign</H2>
      <SubHeader>Define your template</SubHeader>
      <Box sx={{ my: 2 }}>
        <Typography>Use the buttons below to insert columns from your spreadsheet:</Typography>
        <ColumnsChips columns={columns} />
        <TwilioForm columns={columns} />
        <TextEditor />
        <Box sx={{ mt: 2 }}>
          <FormControlLabel
            control={<Checkbox checked={includeOptOut} onChange={handleOptOutChange} />}
            label="Include opt-out message"
          />
          {includeOptOut && (
            <>
              <TextField
                label="Opt Out Message"
                name="unsubMessage"
                value={twilioData.unsubMessage}
                onChange={handleInputChange}
                onFocus={handleFocus}
                fullWidth
                inputProps={{ maxLength: 100 }}
                sx={{ mt: 1 }}
              />
              <TextField
                label="Opt Out Link Text"
                name="unsubLink"
                value={twilioData.unsubLink}
                onChange={handleInputChange}
                onFocus={handleFocus}
                fullWidth
                inputProps={{ maxLength: 30 }}
                sx={{ mt: 1 }}
              />
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6">Preview:</Typography>
                <Typography
                  dangerouslySetInnerHTML={{ __html: previewMessage }}
                  sx={{ backgroundColor: '#f9f9f9', p: 2, borderRadius: 1 }}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Paper>
  );
}

export default TwilioActionConfig;
