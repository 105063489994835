import React, { useEffect, useState } from 'react';
import { Paper, Box, Typography } from '@mui/material';
import { H3 } from '../../../../../../styled-components/Typography';
import { useJobDetails } from '../../../../../../contexts/JobDetailsContext';

const TemplateDetails = ({ jobId }) => {
    const [actionConfig, setActionConfig] = useState({});
    const { getJobTemplateDetails, cardStyle, imgStyle } = useJobDetails();

    useEffect(() => {
        if (jobId) {  // Guard against running if jobId is null or undefined
            const fetchData = async () => {

                const data = await getJobTemplateDetails(jobId);

                setActionConfig(data.action_config);
            };
            fetchData();
        }
    }, [jobId, getJobTemplateDetails]);

    return (
        <Paper elevation={3} sx={{ p: 4, m: 2 }}>
            <H3>Template</H3>
            <Box sx={{ padding: 2 }}>
                <Typography variant="h6">
                    From: {actionConfig.from_name ? actionConfig.from_name : ""} &lt;{actionConfig.from_address ? actionConfig.from_address : actionConfig.from_number}&gt;
                </Typography>
                <Typography variant="h6">
                    To: {actionConfig.to_name ? actionConfig.to_name : " "} &lt;{actionConfig.to_address ? actionConfig.to_address : actionConfig.to_number}&gt;
                </Typography>
                <Typography variant="h6">
                    Subject: {actionConfig.subject}
                </Typography>
                <Typography sx={{ marginTop: 2 }} dangerouslySetInnerHTML={{ __html: actionConfig.body_html ? actionConfig.body_html : actionConfig.body }} />
            </Box>   
        </Paper>
    );
};

export default TemplateDetails;
