// DatabaseManager.js
import React from 'react';
import { Box } from "@mui/material";
import { H2, SubHeader, DataSourceTitle } from "../../../styled-components/Typography";
import DatabaseTypeSelector from "./DatabaseTypeSelector";
import CredentialsForm from "./CredentialsForm";
import TablesSelector from "./TablesSelector";
import TableDisplay from "./TableDisplay";
import QueryRunner from "./QueryRunner";
import { useDatabase } from '../../../contexts/DatabaseContext';

const DatabaseManager = () => {
  // All states and functions come from our context now
  const {
    databaseType,
    setDatabaseType,
    credentials,
    setCredentials,
    connected,
    connectToDatabase,
    tables,
    fetchTableData,
    tableData,
    selectedTable,
    setSelectedTable,
    runQuery,
    queryResult,
    setQueryResult,
    saveQueryResult,
    createView,
    loading,
    loadingQuery,
  } = useDatabase();

  // When user submits credentials, call context.connectToDatabase
  const handleConnect = () => {

    connectToDatabase(databaseType, credentials);
  };

  return (
    <Box sx={{ p: 4 }}>
      <H2>Add Related Database</H2>
      <SubHeader>Select the type of database and provide credentials.</SubHeader>
      <DatabaseTypeSelector 
        databaseType={databaseType} 
        setDatabaseType={setDatabaseType} 
      />
      <CredentialsForm
        credentials={credentials}
        setCredentials={setCredentials}
        onSubmit={handleConnect}
        loading={loading}
        submitLabel="Connect"
      />
      {connected && (
        <>
          <TablesSelector
            tables={tables}
            selectedTable={selectedTable}
            setSelectedTable={setSelectedTable}
            onFetchTableData={fetchTableData}
          />
          <DataSourceTitle>Table Data</DataSourceTitle>
          <TableDisplay 
            columns={tableData.columns} 
            rows={tableData.rows} 
          />
          <QueryRunner
            onRunQuery={runQuery}
            queryResult={queryResult}
            setQueryResult={setQueryResult}
            loadingQuery={loadingQuery}
            onSaveQueryResult={saveQueryResult}
            onCreateView={createView}
          />
        </>
      )}
    </Box>
  );
};

export default DatabaseManager;
